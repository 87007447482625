// Copyright (C) 2022 Deconve Technology. All rights reserved.

import colors from 'vuetify/lib/util/colors';

// Uses the following settings to create an array of colors to be used at chartjs
const AVAILABLE_COLORS = ['lightGreen', 'green', 'teal', 'cyan', 'blue', 'indigo',
  'deepPurple', 'purple', 'pink', 'red', 'lime', 'yellow', 'amber', 'orange', 'deepOrange',
  'brown', 'grey', 'blueGrey'];

const AVAILABLE_BASE_COLORS = ['base', 'lighten3', 'lighten2', ' lighten1'];

export default function chartColors(size: number): string[] {
  const colorList: string[] = [];
  const numberOfColors = AVAILABLE_COLORS.length;
  const numberOfShaders = AVAILABLE_BASE_COLORS.length;

  for (let i = 0; i < size; i += 1) {
    const colorIdx = i % numberOfColors;
    const baseColorIdx = Math.floor(i / numberOfColors) % numberOfShaders;
    const baseColor = AVAILABLE_BASE_COLORS[baseColorIdx];
    const color = AVAILABLE_COLORS[colorIdx];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const selectedColor = (colors as any)[color];

    colorList.push(selectedColor[baseColor]);
  }

  return colorList;
}
