<template>
  <v-container
    class="pa-0 d-flex"
    style="height: 100%; max-width: 1260px;"
    fluid
  >
    <div
      class="pa-2"
      style="width: 100%;"
    >
      <v-row no-gutters>
        <v-col
          class="pa-1"
          style="min-width: 200px; max-width: 520px;"
        >
          <v-select
            v-model="videoIds"
            dense
            outlined
            item-text="name"
            item-value="id"
            multiple
            hide-details
            clearable
            :items="videos"
            :label="$t('deconve.videos')"
            @change="updateReportsByDay"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.name }}</span>
              </v-chip>
              <span
                v-if="index === 1"
                class="grey--text text-caption"
              >
                {{ otherVideoSelectedText }}
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col
          class="pa-1"
          sm="auto"
          align-self="center"
        >
          <div class="d-flex flex-row">
            <date-filter
              width="100%"
              input-class="mr-2"
              :start-date="startDate"
              :end-date="endDate"
              @change-start-date="changeStartDate"
              @change-end-date="changeEndDate"
            />
            <rectangle-button
              icon="mdi-download"
              outlined
              color="primary"
              @clicked="exportReports"
            >
              <div class="d-none d-md-flex">
                {{ $t('deconve.exportReport') }}
              </div>
            </rectangle-button>
          </div>
        </v-col>
      </v-row>
      <v-row
        class="pa-0 ma-0"
      >
        <v-col
          cols="12"
          md="2"
          sm="3"
          class="pa-1"
        >
          <v-card
            outlined
            height="301"
            class="pa-2 pt-0"
            :loading="isLoadingReportsByDay"
          >
            <v-card-title>{{ $t('deconve.total') }}</v-card-title>
            <v-card-subtitle>
              {{ $t('deconve.detectedVehicles') }}
            </v-card-subtitle>
            <v-card-text
              class="subtitle-1 text-h4 font-weight-bold textBlack--text mb-3"
            >
              {{ reportsTotal }}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          class="pa-1"
          cols="12"
          md="10"
          sm="9"
        >
          <v-card
            class="flex-column pa-3 pt-0"
            outlined
            width="100%"
            elevation="0"
            :loading="isLoadingReportsByDay"
          >
            <v-card-title>{{ $t('deconve.detectedVehicles') }}</v-card-title>
            <apexchart
              type="area"
              :options="chartOptions"
              :series="series"
              height="207"
            />
          </v-card>
        </v-col>
      </v-row>
      <v-row
        class="pa-0 ma-0"
      >
        <v-col
          class="pa-1"
          cols="12"
          md="6"
          sm="12"
        >
          <v-card
            class="flex-column pa-3 pt-0"
            outlined
            style="flex: 1"
            elevation="0"
            :loading="isLoadingReportsByDay"
          >
            <v-card-title>{{ $t('deconve.totalByVideo') }}</v-card-title>
            <apexchart
              type="bar"
              width="100%"
              height="100%"
              :options="chartOptionsAudienceByPanel"
              :series="seriesAudienceByPanel"
            />
          </v-card>
        </v-col>
        <v-col
          class="pa-1"
          cols="12"
          md="6"
          sm="12"
        >
          <v-card
            class="flex-column pa-3 ma-0 pt-0"
            outlined
            height="100%"
            width="100%"
            elevation="0"
            :loading="isLoadingReportsByDay"
          >
            <v-card-title>{{ $t('deconve.vehicleTypes') }}</v-card-title>
            <apexchart
              type="donut"
              height="210"
              width="100%"
              :options="chartOptionsPercentageVehicles"
              :series="seriesPercentageVehicles"
            />
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import DateFilter from '@/components/DateFilter.vue';
import chartColors from '@/utils/colors';
import RectangleButton from '../../components/RectangleButton.vue';

export default {
  name: 'Audience',
  components: {
    DateFilter,
    RectangleButton,
  },
  data() {
    return {
      seriesPercentageVehicles: [],
      chartOptionsPercentageVehicles: {
        chart: {
          type: 'donut',
        },
        dataLabels: {
          enabled: false,
        },
        labels: [],
        legend: {
          position: 'right',
        },
      },
      seriesAudienceByPanel: [{
        data: [],
      }],
      chartOptionsAudienceByPanel: {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
      },
      videoIds: [],
      startDate: '',
      endDate: '',
      series: [],
      chartOptions: {
        chart: {
          type: 'area',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          colors: [this.$vuetify.theme.themes.light.borderChart],
          curve: 'smooth',
        },
        xaxis: {
          type: 'datatime',
        },
        fill: {
          colors: [this.$vuetify.theme.themes.light.colorChart],
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100],
          },

        },
      },
      chartDataByDay: {},
      chartDataByHour: {},
      updateReportsTimer: '',
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'vehiclecounter/isLoadingVideos',
      videos: 'vehiclecounter/videos',
      reportsByDay: 'vehiclecounter/reportsByDay',
      reportsTotal: 'vehiclecounter/reportsTotal',
      isLoadingReportsByDay: 'vehiclecounter/isLoadingReportsByDay',
      reportsByDayToChartData: 'vehiclecounter/reportsByDayToChartData',
      reportsPercentage: 'vehiclecounter/reportsPercentage',
      reportsToChartAudience: 'vehiclecounter/reportsToChartAudience',
    }),
    otherVideoSelectedText() {
      const count = this.videoIds.length - 1;

      return this.$tc('deconve.otherItems', count, { count });
    },
  },
  watch: {
    reportsByDayToChartData() {
      const { labels, data } = this.reportsByDayToChartData;
      const { xaxis } = this.chartOptions;

      // to update audience by panels at apexcharts
      this.chartOptions = { ...this.chartOptions, xaxis: { ...xaxis, categories: labels } };
      this.series = [{ data, name: this.$t('deconve.vehicles') }];
    },
    reportsToChartAudience() {
      const { labels, data } = this.reportsToChartAudience;
      const { xaxis } = this.chartOptionsAudienceByPanel;

      // to update audience by panels at apexcharts
      this.chartOptionsAudienceByPanel = {
        ...this.chartOptionsAudienceByPanel,
        xaxis: { xaxis, categories: labels },
      };

      this.seriesAudienceByPanel = [{ data, name: this.$t('deconve.vehicles') }];
    },
    reportsPercentage() {
      const {
        bus, car, motorbike, truck,
      } = this.reportsPercentage;

      this.seriesPercentageVehicles = [car.total, bus.total, motorbike.total, truck.total];

      // to update labels at apexcharts
      this.chartOptionsPercentageVehicles = {
        ...this.chartOptionsPercentageVehicles,
        labels: [
          this.$t('deconve.vehicleType.car', [car.percentage]),
          this.$t('deconve.vehicleType.bus', [bus.percentage]),
          this.$t('deconve.vehicleType.motorbike', [motorbike.percentage]),
          this.$t('deconve.vehicleType.truck', [truck.percentage]),
        ],
      };
    },
  },
  created() {
    this.$moment.locale(this.$i18n.locale);
    this.initDateRange();
    this.getReports();
    this.setTimerToUpdateReports();
  },
  beforeDestroy() {
    clearInterval(this.updateReportsTimer);
  },
  methods: {
    ...mapActions({
      getReportsByDay: 'vehiclecounter/getReportsByDay',
      fetchVehileCounterVideos: 'vehiclecounter/fetchVideos',
      fetchVideos: 'videos/fetchVideos',
      downloadReportByHour: 'vehiclecounter/downloadReportByHour',
    }),
    getReports() {
      Promise.all([this.fetchVideos(), this.fetchVehileCounterVideos()]).then(() => {
        this.updateReportsByDay();
      });
    },
    initDateRange() {
      const oneMonthBefore = this.$moment().subtract(1, 'month');

      this.startDate = oneMonthBefore.startOf('day').toISOString();
      this.endDate = this.$moment().endOf('day').toISOString();
    },
    updateReportsByDay() {
      const params = {
        startDate: this.startDate,
        endDate: this.endDate,
      };

      if (this.videoIds?.length > 0) {
        params.videoIds = this.videoIds;
      }

      this.getReportsByDay(params);
    },
    changeStartDate(newDate) {
      this.startDate = this.$moment(newDate).startOf('day').toISOString();
      this.updateReportsByDay();
    },
    changeEndDate(newDate) {
      this.endDate = this.$moment(newDate).endOf('day').toISOString();
      this.updateReportsByDay();
    },
    exportReports() {
      const params = {
        startDate: this.startDate,
        endDate: this.endDate,
      };

      if (this.videoIds?.length > 0) {
        params.videoIds = this.videoIds;
      }

      this.downloadReportByHour(params);
    },
    addColorToChartData(chartData) {
      const { labels, datasets } = chartData;
      const colors = chartColors(datasets.length);

      datasets.forEach((dataset, index) => {
        datasets[index] = { ...dataset, backgroundColor: colors[index] };
      });

      return { labels, datasets };
    },
    setTimerToUpdateReports() {
      const fiveMinutes = 5 * 60 * 1000;

      this.updateReportsTimer = setInterval(this.getReports, fiveMinutes);
    },
  },
};
</script>
